<template>
	<div class="login__view">
		<div class="pb-10">
			<div class="containeur_logo">
				<img :src="getLogo()" class="logo_login">
			</div>
		</div>
		<v-btn
			dark
			color="#f1830d"
			x-large
			:href="api.auth.getCode()"
		>
			<v-icon left class="fa-google">mdi-google</v-icon>
			Connexion avec google
		</v-btn>
	</div>
</template>

<script>
	import {api} from '../api.js';
	import { utils } from "../utils";
	export default {
		name: 'Login',
		data: () => ({
			api: api,
		}),
		methods: {
			getLogo: function() {
				return utils.display.getCurrentLogoLogin();
			}
		},
	};
</script>

<style lang="scss">
.login__view
{
	text-align: center;
	margin-top: 150px;

	.logo_login {
		width: 350px;
	}
}

.ffci .login__view
{
	.containeur_logo
	{
		width: 99px;
		height: 99px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
	}

	.logo_login
	{
		width: 100px;
	}
}

.pluseo .login__view
{
	.logo_login
	{
		height: 105px;
		width: auto;
	}
}
</style>